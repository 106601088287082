.user-orders-containerrs {
  padding: 20px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.search_input {
  flex: 1;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.search-icon {
  font-size: 18px;
  margin-right: 5px;
}

.orders-list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.orders-list th,
.orders-list td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.autorisé {
  background-color: #d4edda;
}

.nonautorisé {
  background-color: #f8d7da;
}

.action-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: #ff0000;
}

.lodingpromo {
  text-align: center;
  margin-top: 20px;
}

.modiff {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modiff:hover {
  color: #0056b3;
}

.ajouter {
  display: block;
  width: fit-content;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ajouter:hover {
  background-color: #0056b3;
}

.popup-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.close-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
  .search_input {
    width: 100%;
  }
  .orders-list th,
  .orders-list td {
    font-size: 14px;
  }
}
.h1newuserr{
  color: black;
}