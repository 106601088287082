/* inscription.css */
.inscription-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: radial-gradient(circle at 34% 75%, rgba(142, 142, 142,0.06) 0%, rgba(142, 142, 142,0.06) 50%,rgba(11, 11, 11,0.06) 50%, rgba(11, 11, 11,0.06) 100%),radial-gradient(circle at 40% 51%, rgba(12, 12, 12,0.06) 0%, rgba(12, 12, 12,0.06) 50%,rgba(242, 242, 242,0.06) 50%, rgba(242, 242, 242,0.06) 100%),radial-gradient(circle at 50% 87%, rgba(189, 189, 189,0.06) 0%, rgba(189, 189, 189,0.06) 50%,rgba(0, 0, 0,0.06) 50%, rgba(0, 0, 0,0.06) 100%),linear-gradient(90deg, hsl(35,0%,96%),hsl(35,0%,96%));
  }
  
  .inscription-form {
    position: relative;
    background-color: #0b0a0a67;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    margin-top: -2%;
    height: auto; /* Adjust width as needed */
  }
  
  .inscription-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .input-field,
  .select-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  /* Add additional styles for Popup and Popuperror components if needed */
  @media only screen and (max-width: 733px) {
    .inscription-form {
      margin-top: -1%;
    }
  }

  @media only screen and (max-width: 333px) {
    .inscription-form {
      width: 220px;
    }
  }