/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-left: 50%;
    margin-top: 10%;
    background:
      linear-gradient(#ED303C 0 0),
      linear-gradient(#ED303C 0 0),
      linear-gradient(#3B8183 0 0),
      linear-gradient(#3B8183 0 0),
      linear-gradient(#FAD089 0 0),
      linear-gradient(#FAD089 0 0),
      linear-gradient(#FF9C5B 0 0),
      linear-gradient(#FF9C5B 0 0),
      #ED303C;
    background-position: top left,bottom right;
    background-repeat: no-repeat;
    animation: l6 2.5s infinite; 
  }
  @keyframes l6 {
    0%   {background-size: 0   100%,0   100%,100% 0  ,100% 0  ,0   100%,0   100%,100% 0  ,100% 0  }
    20%,
    25%  {background-size: 0   100%,0   100%,100% 0  ,100% 0  ,0   100%,0   100%,100% 50%,100% 50%}
    45%,
    50%  {background-size: 0   100%,0   100%,100% 0  ,100% 0  ,50% 100%,50% 100%,100% 50%,100% 50%}
    70%,
    75%  {background-size: 0   100%,0   100%,100% 50%,100% 50%,50% 100%,50% 100%,100% 50%,100% 50%}
    95%,
    100% {background-size: 50% 100%,50% 100%,100% 50%,100% 50%,50% 100%,50% 100%,100% 50%,100% 50%}
  }