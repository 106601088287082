/* Global Styles */

/* Main Title */
.main-title {
  text-align: left;
  font-size: 1.8rem;
  color: #FF5722; /* Couleur orange vif */
  margin: 20px 10px;
  padding: 10px 20px;
  font-weight: bold;
  border-left: 4px solid #FF5722;
  text-transform: uppercase;
}

/* Container for Products */
.produits-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  padding: 2px;
  background-color: #fff9f2; /* Blanc cassé pour contraste */
  border-radius: 8px;
  margin: 2px auto;
  width: 99%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Product Card */
.produit-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.produit-card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Product Link */
.product-link {
  text-decoration: none;
  color: inherit;
}

/* Product Image */
.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

/* Product Title */
.product-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: #7a5630;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Product Price */
.productpricenew {
  font-size: 1.2rem;
  font-weight: bold;
  color: #a5190c;
  margin: 5px 10px;
  text-align: center;
}

/* Currency Symbol */
.devise {
  font-size: 0.9rem;
  color: #999;
}

/* Delivery Info */
.livraison {
  font-size: 0.9rem;
  color: #29a906;
  margin: 10px 0;
  text-align: center;
}

/* Star Ratings */
.star-filled {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.star-filled .fa-star {
  color: #ffa500; /* Jaune orangé */
  margin: 0 2px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .produits-container {
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes sur tablettes */
  }

  .main-title {
    font-size: 10px;
  }

  .product-title {
    font-size: 0.9rem;
    height: auto;
  }

  .productpricenew {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
 

  .product-title {
    font-size: 0.8rem;
  }

  .productpricenew {
    font-size: 0.9rem;
  }
}
