.contact-container {
  padding: 20px;
  background-color: #f1f0f0;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
 
}

.h2contact {
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.contact-info {
  margin-top: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
  color: #2c2a2a;
}

.pcontact {
  font-size: 16px;
  color: #1c1c1c;
}

.link {
  color: #010c18;
  text-decoration: none;
}

.link:hover {
  color: #0056b3;
}

.contact-image {
  text-align: center;
  margin-top: 20px;
}

.logo {
  width: 150px;
  height: auto;
}
.copiright{
  background-color: #08080800;

}
.copiright{
  text-align: center;
}
.ttc{
  text-decoration: none;
  color: #000000;
}
@media only screen and (max-width: 733px) {
  .copiright{
    background-color: #08080800;
    margin-bottom: 15%;
  }
}