/* Conteneur principal */
.cart-container {
  background: linear-gradient(135deg, #f9f1e7, #f4e1c5);
  padding: 20px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
}

.cart-container h2 {
  font-size: 24px;
  color: #d35400;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #d35400;
  display: inline-block;
  padding-bottom: 5px;
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin: 10px 0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cart-item-details {
  flex: 1;
  display: flex;
  align-items: center;
}

.cart-item-details img {
  max-width: 100px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.cart-item-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.cart-item-info .prixorig {
  font-size: 14px;
  
  color: #7f8c8d;
}

.cart-item-info .prixred {
  font-size: 16px;
  font-weight: bold;
  color: #e67e22;
}

.cart-item-info button {
  background: #d35400;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-item-info button:hover {
  background: #e67e22;
}

/* Prix total */
.cart-total {
  text-align: right;
  margin-top: 20px;
  font-size: 18px;
  color: #2c3e50;
}

.cart-total button {
  background: #d35400;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-total button:hover {
  background: #e67e22;
}

/* Popup */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-content button {
  background: #d35400;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}

.popup-content button:hover {
  background: #e67e22;
}

/* Responsive */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-details img {
    margin: 0 0 10px;
  }

  .cart-item-info {
    align-items: center;
    text-align: center;
  }

  .cart-total {
    text-align: center;
  }
}
