/* dashbordmenu.css */
/* Menu Container */
.menulistcl {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fef8f3;
  padding: 2px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Ombre pour le menu */
  margin-left: 60%;
 
  z-index: 1000;
  border-radius: 2%;

}

/* Menu List */
.menu-listcl {
  list-style: none;
  display: flex;
  gap: 20px;
}

/* Menu Item */
.menu-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Menu Link */
.menu-linkk {
  color: #333;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s;
}

.menu-linkk:hover {
  color: #d4a373;
}

/* Menu Label */
.menu-label {
  font-size: 14px;
  margin-top: 5px;
  color: #555;
  text-transform: capitalize;
}

/* Panier Counter */
.nbpanierr {
  font-size: 12px;
  background-color: #d9534f;
  color: white;
  padding: 2px 6px;
  border-radius: 50%;
  position: absolute;
  margin-left: 10px;
  margin-top: -10px;
}

/* Style général */


/* Position fixe en bas pour les petits écrans */
@media (max-width: 768px) {
  .menulistcl {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 0%;
    z-index: 1000;
  }

  .menu-listcl {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  .menu-item {
    flex: 1; /* Chaque élément prend une largeur égale */
    text-align: center;
    padding: 10px 0;
  }

  .menu-linkk {
    font-size: 18px; /* Taille adaptée pour les mobiles */
    color: #333;
  }

  .menu-label {
    font-size: 12px; /* Texte plus petit sous les icônes */
    margin-top: 3px;
  }
}
