/* Container principal */
.ad-bar-container {
  width: 100%;
  max-width: 1200px;
  height: 250px; /* Taille de l'AdBar */
  margin: 2px auto;
  padding: 10px;
  position: relative;
  background-image: radial-gradient(circle at 33% 41%, rgba(250, 250, 250,0.03) 0%, rgba(250, 250, 250,0.03) 50%,rgba(37, 37, 37,0.03) 50%, rgba(37, 37, 37,0.03) 100%),radial-gradient(circle at 76% 49%, rgba(28, 28, 28,0.03) 0%, rgba(28, 28, 28,0.03) 50%,rgba(187, 187, 187,0.03) 50%, rgba(187, 187, 187,0.03) 100%),radial-gradient(circle at 41% 99%, rgba(247, 247, 247,0.03) 0%, rgba(247, 247, 247,0.03) 50%,rgba(120, 120, 120,0.03) 50%, rgba(120, 120, 120,0.03) 100%),radial-gradient(circle at 66% 27%, rgba(17, 17, 17,0.03) 0%, rgba(17, 17, 17,0.03) 50%,rgba(156, 156, 156,0.03) 50%, rgba(156, 156, 156,0.03) 100%),linear-gradient(0deg, rgb(18, 81, 88),rgb(39, 101, 99));  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

/* Barre d'annonces */
.ad-bar {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Élément d'annonce */
.ad-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transform: translateX(100%); /* Initialement caché à droite */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Élément d'annonce actif */
.ad-item.active {
  opacity: 1;
  transform: translateX(0); /* Transition en douceur */
}

/* Contenu des informations du produit */
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 80px;
  width: 70%;
  word-wrap: break-word;
}

/* Titre du produit */
.infopub {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fafafa;
  margin-bottom: 5px;
}

/* Prix du produit */
.infopub2 {
  font-size: 1.2rem;
  color: #ddf009; /* Rouge artisanat marocain */
  margin-bottom: 10px;
}

/* Bouton d'achat */
.shoppub {
  background-color: #5ab248;
  width: 30%;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.shoppub:hover {
  background-color: #0ae762;
}

/* Image du produit */
.product-image-new {
  width: 25%; /* Largeur initiale sur les grands écrans */
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10%;
}

/* Points de navigation */
.navigation-points {
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.navigation-point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
 
}

.navigation-point.active {
  background-color: #000000;
}

.navigation-point:hover {
  background-color: #884f21;
}

.sppourcentag {
  position: absolute;
  top: 10px;
  right:120px;
  background-color: #e74c3c;
  color: white;
  font-size: 0.8rem;
  padding: 3px 7px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

/* Media Queries pour rendre responsive */

/* Petit écran (tablettes, téléphones) */
@media (max-width: 768px) {
  .ad-bar-container {
    width: 97%;
    height: 200px; /* Ajuster la hauteur */
    flex-direction: column;
    padding: 20px;
    margin-top: 3%;
  }

  .ad-bar {
    flex-direction: column;
    align-items: center;
  }

  .ad-item {
    flex-direction: row; /* Garder l'image et les infos sur la même ligne */
    justify-content: space-between;
    width: 100%;
    text-align: left; /* Garde l'alignement à gauche */
    padding: 15px;
  }

  .product-image-new {
    width: 30%; /* Image plus petite que sur PC */
    margin-right: 20px; /* Espacement entre l'image et le texte */
  }

  .info {
    width: 55%; /* Prendre plus de place */
    margin-left: 10%;
  }

  .infopub {
    font-size: 1.2rem;
    margin-left: 0%;
  }

  .infopub2 {
    font-size: 1rem;
  }

  .shoppub {
    padding: 10px 20px;
    font-size: 1rem;
    width: 100%;
  }
  .sppourcentag {

    top: 20px;
    right:20px;
   
  }
}

/* Très petit écran (téléphones portables) */
@media (max-width: 480px) {
  .ad-bar-container {
    height: 180px;
    padding: 10px;
  }

  .ad-bar {
    flex-direction: column;
    align-items: center;
  }

  .ad-item {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .product-image-new {
    width: 40%; /* Image plus petite */
    margin-right: 0px;
  }

  .info {
    width: 45%; /* Plus de place pour l'info */
    text-align: center; /* Centrer l'info pour les petits écrans */
    margin-left: 0%;
  }

  .infopub {
    font-size: 10px;
  }

  .infopub2 {
    font-size: 0.9rem;
  }

  .shoppub {
    padding: 4px 10px;
    font-size: 0.5rem;
  
  }
}
