/* AddProduct.css */

form {
    max-width: 600px;
    padding: 20px;
    margin-top: -499px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  label,h1 {
    display: block;
    margin-bottom: 8px;
    color: aliceblue;
  }
  
  input,
  select,
  textarea,
  button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  select {
    appearance: none;
    padding: 10px;
    cursor: pointer;
  }
  
  button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  