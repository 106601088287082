.menuserch {
    width: 100%;
    display: flex;
    align-items: first baseline;
    padding: 0px 0px 0px 21px;
    background: transparent;
    margin-top: 5px;
  }
  .formserch{
    border: none;
    position: absolute;
    padding: 0%;
    max-height: 10%;
    margin-top: -4%;
    margin-left: 25%;
  }
  /* Style the currency select */
  .currency-select {
    margin-right: 10px;
    padding: 6px;
    color: #ffffff;
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #070707;
  }
  .search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
  }
  
  .input-search{
    height: 35px;
    width: 340px;
    border-style: none;
    padding: 10px;
    
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: white;
    padding-right: 40px;
    color:#000000;
  }
  .input-search::placeholder{
    color:#00000073;
  
    letter-spacing: 2px;
    
  }
  .input-search:hover{
    background-color: #f6f6f6;
  }
  .btn-search{
    width: 35px;
    height: 35px;
    margin-top: 1%;
    border-style: none;
    font-size: 10px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    right: 0px;
    color:#f5efef ;
    pointer-events: painted;  
    background-color: #0a0a0a;
  }
  .btn-search:hover{
    background-color: #ff8827;
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 10px;
  
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
 
  
  /* serch.css */

/* Style for the category select */
/* serch.css */

/* Style pour le conteneur des catégories */
/* Style pour le conteneur des catégories */
/* Style pour le conteneur des catégories */
/* Style pour le conteneur des catégories */
.category-container {
  display: flex; /* Utiliser flexbox pour afficher les éléments horizontalement */
  margin-top: 1%;
  
  overflow-x: auto; /* Permettre le défilement horizontal si nécessaire */
  -webkit-overflow-scrolling: touch; /* Activer le défilement tactile sur les appareils iOS */
  margin-left: 2%;
  width: 102%;
  background-color: #ffffff00;
  
}

/* Masquer la barre de défilement */
.category-container::-webkit-scrollbar {
  display: none;
}

/* Style pour chaque élément de catégorie */
.category-item {

  padding: 5px; /* Ajouter un peu de rembourrage */
  margin-right: 10px; /* Ajouter de l'espace entre les catégories */
  color: #000000; /* Couleur du texte */
  text-decoration: none; /* Supprimer la décoration de texte par défaut */
  white-space: nowrap; /* Empêcher le saut de ligne des catégories */
  background-color: #ffffff;
  border-radius: 20px;
 
}

/* Style pour le survol de la catégorie */
.category-item:hover {
  background-color: #e20909; /* Changer la couleur de fond au survol */
  color: #ffffff; /* Changer la couleur du texte au survol */
}




/* Additional styling for the search box */
.search-box {
  display: flex;
  align-items: center;
}




.category-select:hover .select-dropdown {
  display: block;
}
  
  /* Style the search input */
  .search-input {
    padding: 5px;
    height: 5%;
    border: none;
    border-radius: 10%;
  }
  .serchsymbol{
    position: absolute;
    top: -11px;
    left: 295px;
    font-size: 1rem;
    color: rgb(0, 0, 0); /* Choose your color */
    border-radius: 10%;
    padding: 3%;
  }
  .fa-search{
   position: relative;
   right: 10%;
   top: -4%;
  }
  select {
    transition: all 0.3s ease-in-out;
  }
  
  /* Add hover effect to selects */
  select:hover {
    background-color: #ffffff;
  }

  .navcateg {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .categ {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .licateg {
    margin: 0 10px;
  }
  
  .menu3 {
    text-decoration: none;
    color: #ffffff; /* Couleur du texte */
   
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* Effet de transition sur la couleur de fond */
  }
  
  .menu3:hover {
    background-color: #ff8827; /* Couleur de fond au survol */
  }
  
  
  @media only screen and (max-width: 733px) {
   
    .formserch{
      border: none;
      position: absolute;
      padding: 0%;
      max-height: 3%;
      margin-top: -9%;
      margin-left: 35%;
      max-width: 10%;
    }
    .input-search{
      height: 20px;
      width: 200px;
      border-style: none;
      font-size: 15px;
      letter-spacing: 2px;
      outline: none;
      border-radius: 25px;
      transition: all .5s ease-in-out;
      background-color: white;
      padding-right: 40px;
      color:#000000;
    }
    .input-search::placeholder{
      font-size: small;
    }
    .btn-search{
     background-color: transparent;
      top: -1%;
     margin-left: -380%;
     color: #000000;
     
    }
    
    .category-select {
      position: relative;
      padding: 2px; 
      width: 90px; /* Adjust the width as needed */
      height: 20px;
      font-size: 10px;
      align-items: start;
    }
    .category-select option {
      background: #fff;
      color: #333;
      max-width : fit-content;
      font-size: 12px;
    }
    .currency-select {
      position: absolute;
      padding: 1px; 
      width: 100px; /* Adjust the width as needed */
      height: 20px;
      margin-left: 80%;
      margin-top: 1%;
      color: #000000;
      width: fit-content;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      background-color: #ffffff;
      font-size: small;
    }
     .navcateg {
      position: relative;
    display: flex;
    justify-content: start;
    padding: 1px;
    margin-top: -2%;
    margin-left: 0%;
  }
    .menu3 {
     
      text-decoration: none;
      margin-top: 0%;
      color: #ffffff; /* Couleur du texte */
      font-size: 8px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      padding: 2px 2px;
      border-radius: 5px;
      transition: background-color 0.3s ease; /* Effet de transition sur la couleur de fond */
      
    }
    .category-container {
      display: flex; /* Utiliser flexbox pour afficher les éléments horizontalement */
      overflow-x: auto; /* Permettre le défilement horizontal si nécessaire */
      -webkit-overflow-scrolling: touch; /* Activer le défilement tactile sur les appareils iOS */
      margin-left: -6%;
      width: 107%;
      margin-top: 2%;
    }
  }
  @media only screen and (max-width: 304px) {
    .formserch{
      border: none;
      position: absolute;
      padding: 0%;
      max-height: 3%;
      margin-top: -9%;
      margin-left: 5%;
      max-width: 10%;
    }
    .input-search{
      height: 15px;
      width: 120px;
      border-style: none;
      font-size: 10px;
      letter-spacing: 2px;
      outline: none;
      border-radius: 25px;
      transition: all .5s ease-in-out;
      background-color: white;
      padding-right: 40px;
      color:#000000;
    }
    .input-search::placeholder{
      font-size: small;
    }
    .btn-search{
      width: 30px;
      height: 20px;
      margin-top: 24%;
      margin-left: -680%;
      border-style: none;
      font-size: 3px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      right: 0px;
    
      pointer-events: painted;  
      
     
    }
    .category-select {
      position: relative;
      padding: 1px; 
      width: 80px; /* Adjust the width as needed */
      height: 15px;
      font-size: 8px;
      align-items: start;
      margin-left: -15%;
    }
    .category-select option {
      background: #fff;
      color: #333;
      max-width : fit-content;
      font-size: 12px;
    }
    .currency-select {
      position: absolute;
      padding: 1px; 
      width: 100px; /* Adjust the width as needed */
      height: 20px;
      margin-left: 72%;
      margin-top: 1%;
      color: #000000;
      width: fit-content;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      background-color: #ffffff;
      font-size: small;
    }
     .navcateg {
      position: relative;
    display: flex;
    justify-content: start;
    padding: 1px;
    margin-top: -2%;
    margin-left: 0%;
  }
    .menu3 {
     
      text-decoration: none;
      margin-top: 0%;
      color: #ffffff; /* Couleur du texte */
      font-size: 6px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      padding: 2px 2px;
      border-radius: 5px;
      transition: background-color 0.3s ease; /* Effet de transition sur la couleur de fond */
    }
  }