/* topprod.css */
#collectionns {
    margin-top: 1px;
    padding: 0%;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #ffffffdc;
  }
  .container-categ{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Utilisez auto-fill */
    gap: 0px;
    padding: 1%;
    justify-content: center; /* Centrer les colonnes horizontalement */
    background-color: rgb(255, 255, 255);
    margin-bottom: 2%;/* Centrer les éléments horizontalement */
  }
  .mmhh1 {
    font-size: 14px;
    margin-bottom: -3%;
    margin-top: 2%;
    font-family: 'Courier New', Courier, monospace;
    color: #121010;
    margin-left: 2%;
    margin-right: 2%;
    
  }
  
  
  
  .content-categ {
    padding: 2px;
    border-radius: 8px; /* Coins arrondis */
   
    position: relative;
  }
  
  .content-categ:hover {
    transform: translateY(-5px);
  }
  
  .imgcateg {
    width: 100%;
  height: auto; /* Ajustez la hauteur selon vos besoins */
  /* Ajuste l'image pour couvrir l'intégralité du conteneur tout en préservant les proportions */
  margin-bottom: 1px;
  border-radius: 3%;
  padding: 1%;
  }
 
  
  
  .content-categ:hover .overlay {
    opacity: 1;
  }
  
  .titrecontentcateg {
    font-size: 14px;
    margin: 10px 0;
    color: #000000;
    padding-left: 0%;
    margin-top: 2%;
    margin-bottom: -1%;
  }
  .descnew{
    color: #1f2121;
    padding-left: 2%;
    margin-top: -2%;
  }

  .prixnew{
    color: rgb(3, 10, 107);
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: -2%;
  }
  
  .linkcateg {
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    background-color: #f63d3d;
    margin-left: 20%;
   
  }
  
  .linkcateg:hover {
    background-color: #ff8808;
  }
  .aucun{
    margin-left: 20%;
    color: #f63d3d;
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }
  
  /* Add any other styles or adjustments based on your preferences */
  @media only screen and (max-width: 733px) {
    .container-categ{
   
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Changer 250px selon vos besoins */
      margin-top: 10%;
    }
    .mmhh1 {
      font-size: 14px;
      margin-bottom: -10%;
      margin-top: 2%;
      font-family: 'Courier New', Courier, monospace;
      color: #121010;
      margin-left: 2%;
      margin-right: 2%;
      
    }
    
    .imgcateg {
      width: 100%;
      height: auto;
    /* Ajustez la hauteur selon vos besoins */
     /* Ajuste l'image pour couvrir l'intégralité du conteneur tout en préservant les proportions */
    margin-bottom: 1px;
    border-radius: 3%;
    padding: 0%;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff17;
    }
   
    .titrecontentcateg {
      font-size: 10px;
     
     
    }
    .prixnew{
      
     margin-bottom: 0%;
    
      font-size: 14px;
    }
    .star-filledcat{
      width: 5px;
    }
  }