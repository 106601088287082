/* Global Styles */


/* Section Title */
.promotionh1 {
  text-align: left;
  font-size: 1.8rem;
  color: #FF5722; /* Couleur orange vif */
  margin: 20px 10px;
  padding: 10px 20px;
  font-weight: bold;
  border-left: 4px solid #FF5722;
  text-transform: uppercase;
}

/* Container for Promo Products */
.collectionspromo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Grille responsive */
  gap: 15px;
  padding: 10px 20px;
}

/* Individual Promo Product Card */
.contentpromo {
  background-color: #FFFFFF;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.contentpromo:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Promo Badge */
.promo {
  position: absolute;

  background-color: #FF4D4F; /* Rouge vif */
  color: #FFFFFF;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 5px;
  z-index: 2;
}

/* Product Image */
.imgpromo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Product Title */
.titrecontentpromo {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px;
  text-align: left;
  color: #7a5630;
  height: 40px; /* Uniformiser la hauteur */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Old Price */
.oldprixpromo {
  font-size: 0.9rem;
  text-decoration: line-through;
  color: #999;
  margin: 5px 10px;
  text-align: left;
}

/* New Price */
.nvprixpromo {
  font-size: 1.1rem;
  font-weight: bold;
  color: #a5190c;
  margin: 5px 10px;
  text-align: left;
}

/* Currency Symbol */
.currencypromo {
  font-size: 0.9rem;
  margin-left: 5px;
  color: #999;
}

/* Star Ratings */
.star-filled {
  color: #FFC107; /* Jaune doré */
}

/* Media Query for Small Screens (Mobile) */
@media screen and (max-width: 768px) {
  .promotionh1 {
    font-size: 1.5rem;
    padding: 5px 10px;
  }
  
  .collectionspromo {
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes sur mobile */
    gap: 10px;
    padding: 1px 2px;
  }

 

  .titrecontentpromo {
    font-size: 0.8rem;
    height: auto; /* Autoriser plusieurs lignes pour les titres */
  }

  .nvprixpromo, .oldprixpromo {
    font-size: 0.9rem;
  }
}

/* Media Query for Very Small Screens (Phones) */
@media screen and (max-width: 480px) {
 

  

  .titrecontentpromo {
    font-size: 0.7rem;
  }

  .nvprixpromo, .oldprixpromo {
    font-size: 0.8rem;
  }
}
