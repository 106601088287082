/* HTML: <div class="loader"></div> */
.loader3 {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-left: 50%;
    margin-top: 10%;
    background: #f79001;
    box-shadow: 0 0 0 0 rgba(252, 19, 19, 0.267);
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  .loader3:before,
  .loader3:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 rgba(211, 8, 8, 0.5);
    animation: inherit;
    animation-delay: -0.5s;
  }
  .loader3:after {
    animation-delay: -1s;
  }
  @keyframes l2 {
      100% {box-shadow: 0 0 0 40px #f1efef9a}
  }