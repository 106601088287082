/* DetailProduit.css */

/* DetailProduit.css */
.detail-produit-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
  flex-wrap: wrap; /* Permet aux éléments de s'adapter sur petits écrans */
}

.img-container {
  flex: 1;
  margin-right: 10px;
  margin-left: 10%;
}

.imgdetail{
  width: 70%;
  margin-bottom: 10px;
  border-radius: 10px;
  
}
.imgdetail1{
  width: 70%;
  margin-bottom: 10px;
  border-radius: 10px;
}

.product-details {
  flex: 2;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.titre {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.size-color-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.size-section,
.color-section {
  flex: 1;
}

.size-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.size-option {
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  border: #333 solid 2px;
  padding: 5px;
  border-radius: 50%;
}

.size-option.selected {
  background-color: #007bff;
  color: white;
}

.color-options {
  display: flex;
  gap: 10px;
  
}

.color-option {
  font-size: 1.5rem;
  cursor: pointer;
  border: #333 solid 2px;
  padding: 5px;
  border-radius: 50%;
}

.color-option.selected {
  border: 5px solid #007bff;
  padding: 5px;
  border-radius: 50%;
}

.old_prix,
.new_prix {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.old_prix {
  text-decoration: line-through;
  color: #777;
}

.new_prix {
  color: #b86f0f;
}

.livraisondet {
  font-size: 1rem;
  color: #259107;
  margin-bottom: 20px;
}

.userv {
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
}

.uservv {
  font-weight: bold;
  color: #035cba;
}

.sppourcentage {
  position: absolute;
  top: 140px;
  left: 320px;
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.quantité {
  width: 60px;
  padding: 5px;
  font-size: 1rem;
  margin-right: 10px;
}

.add-to-cart-btn {
 
  padding: 10px 2px;
  background-color: #33b50b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Expanded image styling */
.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-image {
  max-width: 80%;
  max-height: 80%;
}

.videodet {
  max-width: 80%;
  max-height: 80%;
}

.faclose {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
}

.comment-section {
  background-color: #f8f4ec; /* Couleur beige pour un look artisanal */
  border: 2px solid #e4cda1; /* Encadré inspiré des teintes marocaines */
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.h3cmt {
  font-family: 'Cairo', sans-serif; /* Une typographie élégante et lisible */
  color: #b45b3e; /* Couleur chaude pour évoquer la terre cuite */
  margin-bottom: 15px;
}

.user-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.user-rating .star {
  font-size: 1.5rem;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease;
}

.user-rating .star-filled {
  color: #ffb400; /* Couleur dorée pour les étoiles sélectionnées */
}

.commentaire {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #e4cda1;
  border-radius: 5px;
  font-family: 'Cairo', sans-serif;
  font-size: 1rem;
}

.bttcommantaire {
  background-color: #b45b3e;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.bttcommantaire:hover {
  background-color: #9a4d32;
}

.comments-list {
  margin-top: 20px;
  margin-left: 20px;
}

.comment {
  border-bottom: 1px solid #e4cda1;
  padding: 15px 0;
}

.comment:last-child {
  border-bottom: none;
}

.usercmt {
  font-family: 'Cairo', sans-serif;
  font-weight: bold;
  color: #b45b3e;
}

.textcmt {
  font-family: 'Cairo', sans-serif;
  margin: 5px 0 10px;
}

.comments-list .star {
  
  font-size: 1.2rem;
  color: orange;
}

.comments-list a {
  font-family: 'Cairo', sans-serif;
  color: #b45b3e;
  cursor: pointer;
  text-decoration: underline;
}

.comments-list a:hover {
  color: #9a4d32;
}

/* Responsive Design */
@media (max-width: 768px) {
  .comment-section {
    padding: 15px;
  }

  .h3cmt {
    font-size: 1.2rem;
  }

  .user-rating .star {
    font-size: 1.2rem;
  }

  .commentaire {
    font-size: 0.9rem;
  }

  .bttcommantaire {
    padding: 8px 10px;
    font-size: 0.9rem;
  }

  .comments-list .star {
    font-size: 1rem;
  }
}

/* Media Queries pour les petits écrans */



/* Styles responsifs pour les téléphones */
@media (max-width: 768px) {
  .detail-produit-container {
    flex-direction: column;
    padding: 10px;
  }

  .img-container {
    display: flex; /* Utiliser flexbox pour afficher les éléments horizontalement */
    overflow-x: auto; /* Permettre le défilement horizontal si nécessaire */
    -webkit-overflow-scrolling: touch; /* Activer le défilement tactile sur les appareils iOS */
    margin-left: 1%;
    width: 100%;
    margin-top: 0%;
  }

  .imgdetail{
    width: 100%;
    height: auto;
    margin-top: 0%;
    margin-left: 0%;
  }
   .imgdetail1 {
    width: 100%;
    height: auto;
    margin-left: 2%;
  }
  .faclose{
    position: absolute;
    color: #f80000;
    font-size: 40px;
    margin-left: 72%;
    margin-top: 40%;
  }
  .product-details {
    width: 100%;
    padding: 10px;
  }

  .size-color-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .size-options, .color-options {
    justify-content: flex-start;
  }

  .autrimg {
    display: flex;
 align-items: center;
  margin-top: 10px;
  }

  .old_prix, .new_prix {
    font-size: 1.2em;
  }

  .add-to-cart-btn {
    width: 100%;
    margin-top: 10px;
  }

  .comment-section {
    margin-top: 20px;
  }

  .comments-list {
    margin-top: 10px;
  }

  .expanded-image {
    width: 100%;
    height: auto;
  }
  .sppourcentage{
    position: absolute;
    margin-top: 0%;
    left: 80%;
    background-color: #f00;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .add-to-cart-btn {
   
    border: none;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  .btnadd{
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  .expanded-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .expanded-image {
    max-width: 85%;
    max-height: 85%;
    object-fit: contain;
  }
}

/* CSS for image zoom */

/* DetailProduit.css */

/* Style de base pour les grands écrans */
