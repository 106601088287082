.new-products-container {
  width: 99%;
  margin: auto;
  text-align: center;
}

.h2new {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
  text-align: left;
}

.scroll-container {
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}

.seller-container {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 10px;
  
}

.seller-container::-webkit-scrollbar {
  display: none;
}

.best-seller {
  flex: 0 0 auto;
  width: 150px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden; /* Empêche le contenu de dépasser */
  margin-left: 30px;
}

.best-seller:hover {
  transform: scale(1.05);
}

.product-imagenew {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.titretop {
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
  margin-top: 5px;
  white-space: nowrap; /* Empêche le titre de passer à la ligne */
  overflow: hidden; /* Cache l'excédent de texte */
  text-overflow: ellipsis; /* Ajoute "..." si le texte est trop long */
  width: 100%;
  display: block;
}

.prixtop {
  font-size: 1rem;
  color: #28a745;
  font-weight: bold;
}

.currency {
  font-size: 0.8rem;
  color: #666;
  margin-left: 5px;
}

.symbol-top {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  font-size: 0.8rem;
  padding: 3px 6px;
  border-radius: 5px;
}

.voirplus {
  display: block;
  text-align: right;
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  margin-top: 10px;
}
