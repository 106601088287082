/* Profil.css */

.profile-container {
  max-width: 600px;
  max-height: max-content;
  margin: 0 auto;
  padding: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
  .profill {
    margin-top: -20px;
    background-color: #fdfdfde6;
    box-shadow: #2d352b;
  }
  .linkprofil {
    display: inline-block;
    padding: 10px;
    background-color: #6e5746;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .linkprofil:hover{
    background-color: #222721;
  }
  
  form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #222721;
  }
  
  .inputuser {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  .logout-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    background-color: #ff0000;
    padding: 10px 20px;
    background-color: none;
    display: block;
    width:30%;
    margin-top: -1px;
    text-align: left;
    margin-left: 70%;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .logout-button:hover {
    background-color: none;
    color: #eef7f9;
    background-color: #ff6c09;
  }
  button {
    background-color: #ff6c09;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #d28110;
  }
  
  /* Popup styles */
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .close-btn {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  .pp{
    color: rgb(5, 96, 96);
  }
  .close-btn:hover {
    background-color: #c0392b;
  }
  
  @media only screen and (max-width: 733px) {
    .logout-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #ffffff;
      background-color: #ff0000;
      padding: 5px 10px;
      background-color: none;
      display: block;
      width:50%;
      margin-top: -3px;
      text-align: left;
      margin-left: 50%;
      font-weight: bold;
      transition: background-color 0.3s;
    }
  }